<template>
  <div :class="$style.add">
    <div id="edit" :class="$style.base">
      <p :class="$style.title">基础信息</p>
      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          公告标题
        </div>
        <div :class="$style.right">
          <BjInput v-model="add.title" placeholder="请输入公告标题" />
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          公告封面
        </div>
        <div :class="$style.right">
          <selectMaterial :data="add.cover" :allow="['image']" @change="coverChange" />
          <div class="tip mt-10">建议尺寸614px*240px；大小不超过2M；格式支持JPG、PNG。</div>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">公告摘要</div>
        <div :class="[$style.right, $style.area]">
          <a-textarea
            v-model="add.summary"
            :auto-size="{ minRows: 6, maxRows: 6 }"
            placeholder="选填，如不填则默认读取公告信息的前60字。"
            :max-length="60"
          />
          <span :class="$style.num">{{ add.summary ? add.summary.length : 0 }}/60</span>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          发布状态
        </div>
        <div :class="$style.right" class="line-40">
          <a-radio-group v-model="add.status">
            <a-radio :value="1"> 已发布 </a-radio>
            <a-radio :value="2"> 未发布 </a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>

    <div :class="$style.edit">
      <p :class="$style.title">公告信息</p>
      <BjEditor ref="editor" v-model="add.content" placeholder="请在这里描述公告的详细信息。" />
    </div>
    <div :class="$style.footer" :style="{ width: footWidth }">
      <BjButton v-permission="'app.notice.add-edit'" class="mr-10" type="primary" @click="onSave()">
        <i class="ri-send-plane-2-line left" />
        立即保存
      </BjButton>
      <BjButton @click="onCancel()"> 取消保存 </BjButton>
    </div>
  </div>
</template>

<script>
import BjEditor from '@/components/BjEditor'
import selectMaterial from '@/components/selectMaterial'
import { noticeService } from '@/service'

const service = new noticeService()

export default {
  name: 'add',
  components: {
    selectMaterial,
    BjEditor,
  },
  data() {
    return {
      add: {
        title: null,
        cover: {},
        summary: null,
        status: 1,
        content: null,
      },
      footWidth: '800px',
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getInfo(this.$route.query.id)
    } else {
      this.initAdd()
    }
  },
  mounted() {
    this.footWidth = document.getElementById('edit').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('edit').offsetWidth + 'px'
    }
  },
  methods: {
    async getInfo(_id) {
      try {
        const { data } = await service.getInfo({
          id: _id,
        })
        this.add = data
      } catch (e) {}
    },
    async onSave() {
      try {
        if (!this.$route.query.id && !this.add.summary && this.add.content) {
          const summary = this.$refs.editor.$refs.editor.editor
            .getContent({ format: 'text' })
            .replace(/\s*/g, '')
            .substr(0, 60)
          await service.save({ ...this.add, summary: summary })
        } else {
          this.$route.query.id ? await service.edit(this.add) : await service.save(this.add)
        }
        this.$message.success(this.$route.query.id ? '修改成功' : '添加成功')
        this.$router.push({
          name: 'noticeManage',
        })
      } catch (e) {}
    },
    coverChange(data) {
      this.add.cover = data[0]
    },
    onCancel() {
      this.$router.push({
        name: 'noticeManage',
      })
    },
    initAdd() {
      this.add = {
        title: null,
        cover: {},
        summary: null,
        status: 1,
        content: null,
      }
    },
  },
}
</script>

<style lang="less" module>
.add {
  .base {
    padding: 20px;
    background: #fff;
  }

  .title {
    color: #000;
    font-size: 16px;
  }

  .area {
    position: relative;

    .num {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #5c5c5c;
    }
  }

  .box {
    display: flex;
    margin-top: 20px;

    .left {
      width: 140px;
      line-height: 40px;
    }

    .right {
      width: 600px;
    }
  }

  .edit {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px;
    background: #fff;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }
}
</style>
